var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-page",
    { staticClass: "q-pa-sm", staticStyle: { "background-color": "#fff" } },
    [
      _c(
        "div",
        { staticClass: "row flex-center", staticStyle: { height: "100px" } },
        [
          _c("div", { staticClass: "col-12 text-center" }, [
            _c("img", {
              staticStyle: { "max-width": "125px" },
              attrs: { src: "ovc.png", alt: "OVC Logo" },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }