/* eslint-disable */
<template>
  <q-page class="q-pa-sm" style="background-color: #fff">
    <!-- Top Row  -->
    <div class="row flex-center" style="height: 100px">
      <div class="col-12 text-center">
        <img src="ovc.png" style="max-width: 125px;" alt="OVC Logo" />
      </div>
    </div>
  </q-page>
</template>

<style></style>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "pdf",
  data() {
    return {
      icons: {
        Safety: "fas fa-user-shield",
        Finance: "fas fa-dollar-sign",
        Housing: "fas fa-home",
        Family: "fas fa-user-friends"
      }
    };
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    ...mapGetters({
      UserReport: "getUserReport",
      language: "getLanguage"
    }),
    current_about() {
      return this.UserReport[this.slide].about.split("<br>");
    },

    current_nextSteps() {
      return this.UserReport[this.slide].nextSteps;
    },

    categories() {
      return _.map(this.UserReport, (value, key) => {
        if (
          value.FindHelp.length != 0 ||
          value.MoreInfo.length != 0 ||
          value.NextSteps.length != 0
        ) {
          return key;
        }
      });
    }
  }
};
</script>

<style scoped>
.foundationFont {
  font-family: Arial;
}
.accent {
  color: #603f85;
}
</style>
